<template>
  <div class="width-100 height-100 max_box">
    <!-- <div class="audio_box none">
      <audio src="audio/zhongxin2/Loveless.mp3" ref="bgAudio"></audio>
      <audio ref="audio1" src="https://res.idtcdn.com/Cube/video-config/con-4f57434b.mp3"></audio>
      <audio ref="audio2" src="https://res.idtcdn.com/Cube/video-config/con-e3b4b10c.mp3"></audio>
      <audio ref="audio3" src="https://res.idtcdn.com/Cube/video-config/con-9a88d8a2.mp3"></audio>
      <audio ref="audio4" src="https://res.idtcdn.com/Cube/video-config/con-1d14cc5c.mp3"></audio>
      <audio ref="audio5" src="https://res.idtcdn.com/Cube/video-config/con-f6de5171.mp3"></audio>
      <audio ref="audio6" src="https://res.idtcdn.com/Cube/video-config/con-d059ae8d.mp3"></audio>
      <audio ref="audio7" src="https://res.idtcdn.com/Cube/video-config/con-93cd8f31.mp3"></audio>
    </div> -->
    <div class="cover" id="cover" v-if="isLoadingVisible"></div>
    <div
      class="card1 card animate__animated animate__fadeIn duration"
      v-show="currentIndex == 1"
    >
      <div class="img_box">
        <img src="../../assets/img/cj/cj_1.png" alt="" />
      </div>
      <div class="img_box2">
        <img src="../../assets/img/cj/cj_logo.png" alt="" />
      </div>
      <div class="text">
        <div class="text1">2021/12/13 星期一</div>
        <div class="text2">市场有风险 投资须谨慎</div>
      </div>
      <div class="word1 none">
        <img src="../../assets/img/cj/w1.png" alt="" />
      </div>
      <div class="word2 none">
        <img src="../../assets/img/cj/w2.png" alt="" />
      </div>
      <div class="word3 none">
        <img src="../../assets/img/cj/w3.png" alt="" />
      </div>
      <div class="word4 none">
        <img src="../../assets/img/cj/w4.png" alt="" />
      </div>
    </div>
    <div
      class="card2 card animate__animated animate__fadeIn duration common-card"
      v-show="currentIndex == 2"
    >
      <div class="text">
        <div class="text1">今日市场概况</div>
        <div class="text2">{{ date }}</div>
      </div>
      <div class="title_head none">
        <div class="img_box1">
          <img src="../../assets/img/cj/cj_title.png" alt="" />
        </div>
        <div class="title none" id="title">今日A股主要股指收盘</div>
      </div>
      <div class="box2">
        <ul class="ul1 none">
          <li>上证指数</li>
          <li
            :class="y4[0] > 0 ? 'color_red' : 'color_green'"
            class="none"
            id="num1"
          >
            {{ x[0] }}
          </li>
          <li>
            <span
              class="text1 none"
              :class="y3[0] > 0 ? 'color_red' : 'color_green'"
              ><span>涨跌</span></span
            >
            <span
              class="text2 none"
              :class="y4[0] > 0 ? 'color_red' : 'color_green'"
              ><span>涨跌幅</span></span
            >
          </li>
          <li>
            <span
              class="text1 none"
              :class="y3[0] > 0 ? 'color_red' : 'color_green'"
              ><span v-if="y3[0] > 0">+</span
              ><span id="num2">{{ y3[0] }}</span></span
            >
            <span
              class="text2 none"
              :class="y4[0] > 0 ? 'color_red' : 'color_green'"
              ><span v-if="y4[0] > 0">+</span><span id="num3">{{ y4[0] }}</span
              ><span>%</span></span
            >
          </li>
        </ul>
        <ul class="ul2 none">
          <li>深证成指</li>
          <li
            :class="y4[1] > 0 ? 'color_red' : 'color_green'"
            class="none"
            id="num4"
          >
            {{ x[1] }}
          </li>
          <li>
            <span
              class="text1 none"
              :class="y3[1] > 0 ? 'color_red' : 'color_green'"
              ><span>涨跌</span></span
            >
            <span
              class="text2 none"
              :class="y4[1] > 0 ? 'color_red' : 'color_green'"
              ><span>涨跌幅</span></span
            >
          </li>
          <li>
            <span
              class="text1 none"
              :class="y3[1] > 0 ? 'color_red' : 'color_green'"
              ><span v-if="y3[1] > 0">+</span
              ><span id="num5">{{ y3[1] }}</span></span
            >
            <span
              class="text2 none"
              :class="y4[1] > 0 ? 'color_red' : 'color_green'"
              ><span v-if="y4[1] > 0">+</span><span id="num6">{{ y4[1] }}</span
              ><span>%</span></span
            >
          </li>
        </ul>
        <ul class="ul3 none">
          <li>创业板指</li>
          <li
            :class="y4[2] > 0 ? 'color_red' : 'color_green'"
            class="none"
            id="num7"
          >
            {{ x[2] }}
          </li>
          <li>
            <span
              class="text1 none"
              :class="y3[2] > 0 ? 'color_red' : 'color_green'"
              ><span>涨跌</span></span
            >
            <span
              class="text2 none"
              :class="y4[2] > 0 ? 'color_red' : 'color_green'"
              ><span>涨跌幅</span></span
            >
          </li>
          <li>
            <span
              class="text1 none"
              :class="y3[2] > 0 ? 'color_red' : 'color_green'"
              ><span v-if="y3[2] > 0">+</span
              ><span id="num8">{{ y3[2] }}</span></span
            >
            <span
              class="text2 none"
              :class="y4[2] > 0 ? 'color_red' : 'color_green'"
              ><span v-if="y4[2] > 0">+</span><span id="num9">{{ y4[2] }}</span
              ><span>%</span></span
            >
          </li>
        </ul>
        <ul class="ul4 none">
          <li>中小板综</li>
          <li
            :class="y4[3] > 0 ? 'color_red' : 'color_green'"
            class="none"
            id="num10"
          >
            {{ x[3] }}
          </li>
          <li>
            <span
              class="text1 none"
              :class="y3[3] > 0 ? 'color_red' : 'color_green'"
              ><span>涨跌</span></span
            >
            <span
              class="text2 none"
              :class="y4[3] > 0 ? 'color_red' : 'color_green'"
              ><span>涨跌幅</span></span
            >
          </li>
          <li>
            <span
              class="text1 none"
              :class="y3[3] > 0 ? 'color_red' : 'color_green'"
              ><span v-if="y3[3] > 0">+</span
              ><span id="num11">{{ y3[3] }}</span></span
            >
            <span
              class="text2 none"
              :class="y4[3] > 0 ? 'color_red' : 'color_green'"
              ><span v-if="y4[3] > 0">+</span><span id="num12">{{ y4[3] }}</span
              ><span>%</span></span
            >
          </li>
        </ul>
        <ul class="ul5 none">
          <li>沪深两市成交额</li>
          <li class="color_red none">
            <span id="num13">{{ data1[0] }} </span><span>亿元</span>
          </li>
        </ul>
        <ul class="ul6 none">
          <li>主力资金净流入</li>
          <li class="color_green none">
            <span id="num14">{{ data2[0] }}</span> <span>亿元</span>
          </li>
        </ul>
      </div>
      <div class="footer">
        <div class="txt1">数据来源：长江证券Level2软件</div>
        <div class="txt2">市场有风险 投资须谨慎</div>
      </div>
    </div>
    <div
      class="card3 card animate__animated animate__fadeIn duration common-card"
      v-show="currentIndex == 3"
    >
      <div class="text">
        <div class="text1">
          今日市场概括——<span class="small">行业板块</span>
        </div>
        <div class="text2">{{ date }}</div>
      </div>
      <div class="title_head none">
        <div class="img_box1">
          <img src="../../assets/img/cj/cj_title.png" alt="" />
        </div>
        <div class="title none" id="title2">今日主力资金流入排名</div>
      </div>
      <div class="box">
        <bar-chart-horizen
          ref="refBarChart1"
          id="refBarChart1"
          customClass="c_bar1"
        >
        </bar-chart-horizen>
        <div class="num1 num none">NO.1</div>
        <div class="num2 num none">NO.2</div>
        <div class="num3 num none">NO.3</div>
      </div>
      <div class="footer">
        <div class="txt1">数据来源：长江证券Leve12软件</div>
        <div class="txt2">市场有风险 投资须谨慎</div>
      </div>
    </div>
    <div
      class="card4 card animate__animated animate__fadeIn duration common-card"
      v-show="currentIndex == 4"
    >
      <div class="text">
        <div class="text1">
          今日市场概括——<span class="small">行业板块</span>
        </div>
        <div class="text2">{{ date }}</div>
      </div>
      <div class="title_head none">
        <div class="img_box1">
          <img src="../../assets/img/cj/cj_title.png" alt="" />
        </div>
        <div class="title none" id="title3">今日主力资金流出排名</div>
      </div>
      <div class="box">
        <bar-chart-horizen
          ref="refBarChart2"
          id="refBarChart2"
          customClass="c_bar1"
        >
        </bar-chart-horizen>
        <div class="num1 num none">NO.1</div>
        <div class="num2 num none">NO.2</div>
        <div class="num3 num none">NO.3</div>
      </div>
      <div class="footer">
        <div class="txt1">数据来源：长江证券Leve12软件</div>
        <div class="txt2">市场有风险 投资须谨慎</div>
      </div>
    </div>

    <div
      class="card5 card animate__animated animate__fadeIn duration common-card"
      v-show="currentIndex == 5"
    >
      <div class="text">
        <div class="text1">今日主力资金之最</div>
        <div class="text2">{{ date }}</div>
      </div>
      <div class="title_head none">
        <div class="img_box1">
          <img src="../../assets/img/cj/cj_title.png" alt="" />
        </div>
        <div class="title none" id="title4">今日主力资金净流入排行</div>
      </div>
      <div class="box">
        <!-- <div id="chart3"></div> -->
        <rose-chart
          ref="refRoseChart1"
          id="refRoseChart1"
          customClass="c_rose1"
        >
        </rose-chart>
        <div class="tips">个股仅做数据展示，不构成投资建议</div>
      </div>
      <div class="footer">
        <div class="txt1">数据来源：长江证券Leve12软件</div>
        <div class="txt2">市场有风险 投资须谨慎</div>
      </div>
    </div>
    <div
      class="card6 card animate__animated animate__fadeIn duration common-card"
      v-show="currentIndex == 6"
    >
      <div class="text">
        <div class="text1">今日主力资金之最</div>
        <div class="text2">{{ date }}</div>
      </div>
      <div class="title_head none">
        <div class="img_box1">
          <img src="../../assets/img/cj/cj_title.png" alt="" />
        </div>
        <div class="title none" id="title5">今日主力资金净流出排行</div>
      </div>
      <div class="box">
        <!-- <div id="chart3"></div> -->
        <rose-chart
          ref="refRoseChart2"
          id="refRoseChart2"
          customClass="c_rose2"
        >
        </rose-chart>
        <div class="tips">个股仅做数据展示，不构成投资建议</div>
      </div>
      <div class="footer">
        <div class="txt1">数据来源：长江证券Leve12软件</div>
        <div class="txt2">市场有风险 投资须谨慎</div>
      </div>
    </div>
    <div
      class="card7 card animate__animated animate__fadeIn duration common-card"
      v-show="currentIndex == 7"
    >
      <div class="text">
        <div class="text1">近五日个股主力资金之最</div>
        <div class="text2">{{ date }}</div>
      </div>
      <div class="title_head none">
        <div class="img_box1">
          <img src="../../assets/img/cj/cj_title.png" alt="" />
        </div>
        <div class="title none" id="title6">近五日个股主力资金净流入排行</div>
      </div>
      <div class="box">
        <!-- <div id="chart3"></div> -->
        <rose-chart
          ref="refRoseChart3"
          id="refRoseChart3"
          customClass="c_rose3"
        >
        </rose-chart>
        <div class="tips">个股仅做数据展示，不构成投资建议</div>
      </div>
      <div class="footer">
        <div class="txt1">数据来源：长江证券Leve12软件</div>
        <div class="txt2">市场有风险 投资须谨慎</div>
      </div>
    </div>
    <div
      class="card8 card animate__animated animate__fadeIn duration common-card"
      v-show="currentIndex == 8"
    >
      <div class="text">
        <div class="text1">近五日个股主力资金之最</div>
        <div class="text2">{{ date }}</div>
      </div>
      <div class="title_head none">
        <div class="img_box1">
          <img src="../../assets/img/cj/cj_title.png" alt="" />
        </div>
        <div class="title none" id="title7">近五日个股主力资金净流出排行</div>
      </div>
      <div class="box">
        <rose-chart
          ref="refRoseChart4"
          id="refRoseChart4"
          customClass="c_rose4"
        >
        </rose-chart>
        <div class="tips">个股仅做数据展示，不构成投资建议</div>
      </div>
      <div class="footer">
        <div class="txt1">数据来源：长江证券Leve12软件</div>
        <div class="txt2">市场有风险 投资须谨慎</div>
      </div>
    </div>
    <div
      class="card9 card animate__animated animate__fadeIn duration"
      v-show="currentIndex == 9"
    >
      <div class="img_box">
        <img src="../../assets/img/cj/cj_tip.png" alt="" />
      </div>
      <div class="text none">
        <div class="line"></div>
        <div class="title">重要声明和风险提示</div>
        <div class="line2"></div>
      </div>
      <div class="content">
        <p class="none">
          本视频的信息均来源于公开资料，长江证券股份有限公司(以下简称：长江证券）对这些信息的准确性和完整性不作任何保证，也不保证所包含的信息和建议不会发生任何变更。
        </p>
        <p class="none">
          长江证券已力求视频内容的客观、公正，但其中的观点、结论和建议仅供参考，不代表对证券涨跌或市场走势的确定性判断。视频中的信息或意见并不构成所述证券的买卖出价或征价，投资者据此做出的任何决策与长江证券无关。
        </p>
        <p class="none">
          本视频版权仅为长江证券所有，未经书面许可，任何机构和个人不得以任何形式翻版、复制和发布。如引用、刊发，需注明出处为长江证券，且不得对本视频进行有悖原意的引用、删节和修改。
        </p>
        <p class="none">
          本视频中引用的宣传材料不构成投资建议和产品推荐，相关产品详情请咨询您的客户经理。
        </p>
        <p style="margin-bottom: 15px" class="none">市场有风险，投资需谨慎</p>
        <div class="info none">
          <ul style="margin-bottom: 15px">
            <li>长江证券统一客服热线：95579</li>
            <li>传真：027-85481900</li>
            <li>电子信箱：service@95579.com</li>
            <li>长江证券股份有限公司 2004-2022</li>
          </ul>
        </div>
        <p class="none">
          从事证券投资，请通过合法证券期货经营机构进行合法机构名单可至中国证监会网站（www.csrc.gov.cn）查询
        </p>
      </div>
    </div>
    <div
      class="card10 card animate__animated animate__fadeIn duration"
      v-show="currentIndex == 10"
    >
      <div class="img_box">
        <img src="../../assets/img/cj/cj_bg.png" alt="" />
      </div>
      <div class="title none">
        <div class="text1">长江Level2</div>
        <div class="text2" style="margin-bottom: 16px">不止十档行情</div>
        <div class="line"></div>
      </div>
      <div class="more none">更多主力信息，前往长江e号查看</div>
      <div class="content">
        <div class="img_box none">
          <img src="../../assets/img/cj/cj_code.png" alt="" />
        </div>
        <div class="text none" id="text">扫描二维码进行下载</div>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import { GetParams, formatToDate, GetParams2 } from "../../utils/common";
import { anyElement } from "@/components/anyElement.js";
import roseChart from "@/components/roseChart/index.vue";
import barChartHorizen from "@/components/barChartHorizen/index.vue";
import { sleep, numberRoll } from "@/utils/common";
import ApiServe from "@/api/index";
export default {
  name: "changjiang",
  components: { roseChart, barChartHorizen },
  data() {
    return {
      audioSrcList: [],
      audioTimeList: [],
      device_id: null,
      recordtimeAll: null,
      id: "",
      model_id: null,
      model_name: "",
      model_category: "",
      url_extra_params: null,
      ip: "",
      port: "",
      offset_x: "",
      offset_y: "",
      width: "",
      height: "",
      source: "",
      audioData: [],
      currentIndex: 1,
      x: [],
      y3: [],
      y4: [],
      data1: [],
      data2: [],
      date: "",
      chart1_data: {},
      chart2_data: {},
      chart3_data: {},
      chart4_data: {},
      chart5_data: {},
      chart6_data: {},
      isLoadingVisible: true,
    };
  },
  created() {
    console.log(666);
    this.id = GetParams2().id || null;
    this.model_id = GetParams2().model_id || 1;
    this.model_name = GetParams2().model_name || null;
    this.model_category = GetParams2().model_category || null;
    this.url_extra_params = GetParams2().url_extra_params || null;
    this.ip = GetParams2().ip || null;
    this.port = GetParams2().port || null;
    this.offset_x = GetParams2().offset_x || null;
    this.offset_y = GetParams2().offset_y || null;
    this.width = GetParams2().width || null;
    this.height = GetParams2().height || null;
    this.source = GetParams2().source || null;
    this.getData();
    // this.getAudio();
  },
  computed: {
    allReady() {
      const { date } = this;
      return {
        date,
      };
    },
    newIpPort() {
      if (this.source == "ali") {
        return "https://recordvideoali.wedengta.com";
      } else {
        return "https://recordvideo.wedengta.com";
      }
    },
  },
  watch: {
    allReady(val) {
      if (val.date.length > 0) {
        if (this.ip && this.port) {
          console.log(666);
          const params = {
            ip: this.ip,
            port: this.port,
            offset_x: this.offset_x,
            offset_y: this.offset_y,
            width: this.width,
            height: this.height,
          };
          ApiServe.recordstartPc(this.newIpPort, params)
            .then(({ code }) => {})
            .catch((err) => {
              console.log(err);
              ApiServe.recordreleasePc(this.newIpPort, {
                ip: this.ip,
                port: this.port,
              });
            });
          this.load();
        } else {
          // alert("没获取到ip,port");
          this.load();
        }
      }
    },
  },
  //   async mounted() {
  //     this.getData();
  //   },
  methods: {
    //接口数据列转行方法
    rowToColumn(data) {
      if (!data) return [];
      const keys = Object.keys(data);
      if (keys.length === 0) return [];
      const len = data[keys[0]].length;
      return Array.from({ length: len }).map((_, index) => {
        return keys.reduce((t, key) => {
          t[key] = data[key] && data[key][index];
          return t;
        }, {});
      });
    },
    playRoseChartIn1(refName) {
      this.$refs[refName].DonutChart(this.chart3_data, {
        name: (d) => d.stock_name,
        value: (d) => d.superin,
        width: 660,
        height: 740,
        donutRatioRange: [0.1, 0.3, 0.65], //空心内圈的半径占比，最小半径占比和最大半径占比
        colors: [
          "#C9253B",
          "#D5313F",
          "#D74142",
          "#D95046",
          "#D95046",
          "#DE6F4D",
          "#E07E51",
          "#E18D53",
          "#E49C57",
          "#E6AB5A",
        ],
        duration: 1000,
        labelOffset: 50,
        top3Offset: 50,
        isRotateLabel: true,
        isSameAngle: true,
        delay: 80,
        ease: "easeBackOut",
        valueType: "absolute",
        valueSuffix: "亿元",
        isSort: true,
      });
    },
    playRoseChartIn2(refName) {
      this.$refs[refName].DonutChart(this.chart4_data, {
        name: (d) => d.stock_name,
        value: (d) => d.superin,
        width: 660,
        height: 740,
        donutRatioRange: [0.1, 0.3, 0.65], //空心内圈的半径占比，最小半径占比和最大半径占比
        colors: [
          "#666D7A",
          "#737884",
          "#818690",
          "#737884",
          "#818690",
          "#9B9FA6",
          "#C2C4C8",
          "#C2C4C8",
          "#D0D1D3",
          "#DDDDDF",
        ],
        duration: 340,
        labelOffset: 50,
        isRotateLabel: true,
        delay: 40,
        ease: "easeBackOut",
        isSameAngle: true,
        valueType: "absolute",
        valueSuffix: "亿元",
        isSort: true,
      });
    },
    playRoseChartIn3(refName) {
      this.$refs[refName].DonutChart(this.chart5_data, {
        name: (d) => d.stock_name,
        value: (d) => d.superinn,
        width: 655,
        height: 740,
        donutRatioRange: [0.1, 0.3, 0.65], //空心内圈的半径占比，最小半径占比和最大半径占比
        colors: [
          "#C9253B",
          "#D5313F",
          "#D74142",
          "#D95046",
          "#D95046",
          "#DE6F4D",
          "#E07E51",
          "#E18D53",
          "#E49C57",
          "#E6AB5A",
        ],
        duration: 340,
        delay: 40,
        labelOffset: 50,
        isRotateLabel: true,
        isSameAngle: true,
        ease: "easeBackOut",
        valueType: "absolute",
        valueSuffix: "亿元",
        isSort: true,
      });
    },
    playRoseChartIn4(refName) {
      this.$refs[refName].DonutChart(this.chart6_data, {
        name: (d) => d.stock_name,
        value: (d) => d.superinn,
        width: 655,
        height: 740,
        donutRatioRange: [0.1, 0.3, 0.65], //空心内圈的半径占比，最小半径占比和最大半径占比
        colors: [
          "#333333",
          "#737884",
          "#818690",
          "#737884",
          "#818690",
          "#9B9FA6",
          "#C2C4C8",
          "#C2C4C8",
          "#D0D1D3",
          "#DDDDDF",
        ],
        duration: 340,
        delay: 40,
        ease: "easeBackOut",
        isSameAngle: true,
        labelOffset: 50,
        isRotateLabel: true,
        valueType: "absolute",
        valueSuffix: "亿元",
        isSort: true,
      });
    },

    playBarChartIn1(refName) {
      this.$refs[refName].BarChart(this.chart1_data, {
        x: (d) => d.superin,
        y: (d) => d.industry_name,
        width: 400,
        height: 500,
        marginLeft: 80,
        marginRight: 180,
        duration: 1000,
        delay: 500,
        yPadding: 0.6,
        // isLabelAnchorStart: true,
        ease: "easeBack",
        numberSuffix: "亿",
        numberPosition: "outside",
      });
    },
    playBarChartIn2(refName) {
      this.$refs[refName].BarChart(this.chart2_data, {
        x: (d) => d.superin,
        y: (d) => d.industry_name,
        width: 400,
        height: 500,
        marginLeft: 120,
        marginRight: 100,
        duration: 1000,
        delay: 500,
        yPadding: 0.6,
        isLabelAnchorStart: true,
        ease: "easeBack",
        numberSuffix: "亿",
        numberPosition: "outside",
      });
    },
    getData() {
      let params = {
        model_id: 60,
      };
      ApiServe.getDataPC(params)
        .then(({ data, code }) => {
          if (code == 200) {
            console.log(data);
            this.x = data.chart_data_1[0].data.x;
            this.y3 = data.chart_data_1[0].data.y3;
            this.y4 = data.chart_data_1[0].data.y4;
            this.data1 = data.chart_data_1[1].data.amount;
            this.data2 = data.chart_data_1[2].data.superin;
            this.date = data.extra_param_1[0];

            //chart1
            this.chart1_data = this.rowToColumn(data.chart_data_2[0].data);
            console.log(this.chart1_data);

            this.chart2_data = this.rowToColumn(data.chart_data_3[0].data);

            this.chart3_data = this.rowToColumn(data.chart_data_4[0].data);
            console.log(this.chart3_data);
            this.chart4_data = this.rowToColumn(data.chart_data_5[0].data);
            this.chart5_data = this.rowToColumn(data.chart_data_8[0].data);
            console.log(this.chart5_data);
            this.chart6_data = this.rowToColumn(data.chart_data_9[0].data);
            console.log(this.chart6_data);
          }
        })
        .catch((err) => {
          if (this.ip) {
            ApiServe.recordreleasePc({ ip: this.ip, port: this.port });
          }
        });
    },

    playIn(inEffect, outEffect, dom) {
      const _outEffect = outEffect == undefined ? "fadeOut" : outEffect;
      this.txt = null;
      this.txt2 = null;
      this.txt = anyElement({
        targets: document.querySelector(dom),
        isSplitTxt: true,
        duration: 1200,
        delay: 60,
        inEffect: inEffect,
        outEffect: _outEffect,
      });
      this.txt.goIn.restart();
    },

    playOut() {
      this.txt.goOut.restart();
    },
    endRecord() {
      this.aduioData = [
        {
          audioLink:
            "https://res.idtcdn.com/Cube/video-config/con-59e85370.mp3",
          timeIndex: 5,
          audioLen: 12,
        },
        {
          audioLink:
            "https://res.idtcdn.com/Cube/video-config/con-e3b4b10c.mp3",
          timeIndex: 23,
          audioLen: 5,
        },
        {
          audioLink:
            "https://res.idtcdn.com/Cube/video-config/con-9a88d8a2.mp3",
          timeIndex: 33,
          audioLen: 3,
        },
        {
          audioLink:
            "https://res.idtcdn.com/Cube/video-config/con-1d14cc5c.mp3",
          timeIndex: 38,
          audioLen: 6,
        },
        {
          audioLink:
            "https://res.idtcdn.com/Cube/video-config/con-f6de5171.mp3",
          timeIndex: 46,
          audioLen: 4,
        },
        {
          audioLink:
            "https://res.idtcdn.com/Cube/video-config/con-d059ae8d.mp3",
          timeIndex: 51,
          audioLen: 6,
        },
        {
          audioLink:
            "https://res.idtcdn.com/Cube/video-config/con-93cd8f31.mp3",
          timeIndex: 60,
          audioLen: 4,
        },
      ];
      const data = {
        id: this.id,
        model_id: this.model_id,
        videoTotalTime: 71,
        videmBgm: "https://res.idtcdn.com/Cube/video-config/con-ec094cc4.mp3",
        audiosOrderList: this.aduioData,
      };
      ApiServe.recordstopPc(this.newIpPort, { ip: this.ip, port: this.port })
        .then(() => {
          ApiServe.combineaudioswithbgm(
            this.newIpPort,
            this.ip,
            this.port,
            data
          )
            .then(() => {
              ApiServe.recordreleasePc(this.newIpPort, {
                ip: this.ip,
                port: this.port,
              });
            })
            .catch(() => {
              ApiServe.recordreleasePc(this.newIpPort, {
                ip: this.ip,
                port: this.port,
              });
            });
        })
        .catch(() => {
          ApiServe.recordreleasePc(this.newIpPort, {
            ip: this.ip,
            port: this.port,
          });
        });
    },
    // 页面card一个接一个播放
    async load() {
      // this.$refs.bgAudio.volume = 0.1;
      // this.$refs.bgAudio.play();
      await sleep(100);
      this.isLoadingVisible = false;
      // card1
      this.currentIndex = 1;
      await sleep(1000);
      $(".word1").removeClass("none");
      $(".word1").addClass(" animate__animated animate__fadeIn");
      await sleep(800);
      $(".word2").removeClass("none");
      $(".word2").addClass(" animate__animated animate__fadeIn");
      await sleep(800);
      $(".word3").removeClass("none");
      $(".word3").addClass(" animate__animated animate__fadeIn");
      await sleep(800);
      $(".word4").removeClass("none");
      $(".word4").addClass(" animate__animated animate__fadeIn");
      await sleep(1000);

      // card2
      this.currentIndex = 2;
      await sleep(1000);
      $(".card2 .title_head").removeClass("none");
      $(".card2 .title_head").addClass(
        " animate__animated animate__fadeInLeft"
      );
      await sleep(600);
      $(".card2 .title").removeClass("none");
      this.playIn("fadeIn", "fadeOut", "#title");
      //ul1
      await sleep(600);
      // this.$refs.audio1.play();
      $(".ul1").removeClass("none");
      $(".ul1").addClass(" animate__animated animate__fadeIn");
      await sleep(600);
      $(".ul1 li").eq(1).removeClass("none");
      numberRoll("num1", this.x[0], 2);
      await sleep(800);
      $(".ul1 .text1").removeClass("none");
      numberRoll("num2", this.y3[0], 2);
      await sleep(800);
      $(".ul1 .text2").removeClass("none");
      numberRoll("num3", this.y4[0], 2);

      //ul2
      await sleep(600);
      $(".ul2").removeClass("none");
      $(".ul2").addClass(" animate__animated animate__fadeIn");
      await sleep(600);
      $(".ul2 li").eq(1).removeClass("none");
      numberRoll("num4", this.x[1], 2);
      await sleep(800);
      $(".ul2 .text1").removeClass("none");
      numberRoll("num5", this.y3[1], 2);
      await sleep(800);
      $(".ul2 .text2").removeClass("none");
      numberRoll("num6", this.y4[1], 2);

      //ul3
      await sleep(600);
      $(".ul3").removeClass("none");
      $(".ul3").addClass(" animate__animated animate__fadeIn");
      await sleep(600);
      $(".ul3 li").eq(1).removeClass("none");
      numberRoll("num7", this.x[2], 2);
      await sleep(800);
      $(".ul3 .text1").removeClass("none");
      numberRoll("num8", this.y3[2], 2);
      await sleep(800);
      $(".ul3 .text2").removeClass("none");
      numberRoll("num9", this.y4[2], 2);

      //ul4
      await sleep(600);
      $(".ul4").removeClass("none");
      $(".ul4").addClass(" animate__animated animate__fadeIn");
      await sleep(600);
      $(".ul4 li").eq(1).removeClass("none");
      numberRoll("num10", this.x[3], 2);
      await sleep(800);
      $(".ul4 .text1").removeClass("none");
      numberRoll("num11", this.y3[3], 2);
      await sleep(800);
      $(".ul4 .text2").removeClass("none");
      numberRoll("num12", this.y4[3], 2);

      //ul5
      await sleep(600);
      $(".ul5").removeClass("none");
      $(".ul5").addClass(" animate__animated animate__fadeIn");
      $(".ul5 li").eq(1).removeClass("none");
      numberRoll("num13", this.data1[0], 2);
      await sleep(800);

      //ul6
      await sleep(600);
      $(".ul6").removeClass("none");
      $(".ul6").addClass(" animate__animated animate__fadeIn");
      $(".ul6 li").eq(1).removeClass("none");
      numberRoll("num14", this.data2[0], 2);
      await sleep(2500);
      // card3
      this.currentIndex = 3;
      // this.$refs.audio2.play();
      await sleep(1000);
      $(".card3 .title_head").removeClass("none");
      $(".card3 .title_head").addClass(
        " animate__animated animate__fadeInLeft"
      );
      await sleep(1000);
      $(".card3 .title").removeClass("none");
      this.playIn("fadeIn", "fadeOut", "#title2");
      await sleep(1000);
      this.playBarChartIn1("refBarChart1");
      await sleep(1000);
      $(" .card3 .num1").removeClass("none");
      $(" .card3 .num1").addClass("animate__animated animate__flash");
      await sleep(1000);
      $(" .card3 .num2").removeClass("none");
      $(".card3 .num2").addClass("animate__animated animate__fadeIn");
      await sleep(1000);
      $(".card3 .num3").removeClass("none");
      $(".card3 .num3").addClass("animate__animated animate__fadeIn");
      await sleep(2500);

      // card4
      this.currentIndex = 4;
      $(" .card4 .title_head").removeClass("none");
      $(" .card4 .title_head").addClass(
        " animate__animated animate__fadeInLeft"
      );
      await sleep(1000);
      $(" .card4 .title").removeClass("none");
      this.playIn("fadeIn", "fadeOut", "#title3");
      await sleep(1000);
      this.playBarChartIn2("refBarChart2");
      await sleep(1000);
      // this.$refs.audio3.play();
      $(" .card4 .num1").removeClass("none");
      $(".card4 .num1").addClass("animate__animated animate__flash");
      await sleep(1000);
      $(".card4 .num2").removeClass("none");
      $(".card4 .num2").addClass("animate__animated animate__fadeIn");
      await sleep(1000);
      $(".card4 .num3").removeClass("none");
      $(".card4 .num3").addClass("animate__animated animate__fadeIn");
      await sleep(2500);

      // card5
      this.currentIndex = 5;
      // this.$refs.audio4.play();
      $(".card5 .title_head").removeClass("none");
      $(".card5 .title_head").addClass(
        " animate__animated animate__fadeInLeft"
      );
      await sleep(1000);
      $(" .card5 .title").removeClass("none");
      this.playIn("fadeIn", "fadeOut", "#title4");
      await sleep(1000);
      this.playRoseChartIn1("refRoseChart1");
      await sleep(5000);
      //card6
      this.currentIndex = 6;
      // this.$refs.audio5.play();
      $(".card6 .title_head").removeClass("none");
      $(".card6 .title_head").addClass(
        " animate__animated animate__fadeInLeft"
      );
      await sleep(1000);
      $(" .card6 .title").removeClass("none");
      this.playIn("fadeIn", "fadeOut", "#title5");
      await sleep(1000);
      this.playRoseChartIn2("refRoseChart2");
      await sleep(4000);
      //card7
      this.currentIndex = 7;
      // this.$refs.audio6.play();
      $(".card7 .title_head").removeClass("none");
      $(".card7 .title_head").addClass(
        " animate__animated animate__fadeInLeft"
      );
      await sleep(1000);
      $(" .card7 .title").removeClass("none");
      this.playIn("fadeIn", "fadeOut", "#title6");
      await sleep(1000);
      this.playRoseChartIn3("refRoseChart3");
      await sleep(5000);
      //card8
      this.currentIndex = 8;
      // this.$refs.audio7.play();
      $(".card8 .title_head").removeClass("none");
      $(".card8 .title_head").addClass(
        " animate__animated animate__fadeInLeft"
      );
      await sleep(1000);
      $(" .card8 .title").removeClass("none");
      this.playIn("fadeIn", "fadeOut", "#title7");
      await sleep(1000);
      this.playRoseChartIn4("refRoseChart4");
      await sleep(4000);

      // card9
      this.currentIndex = 9;
      $(".card9 .text").removeClass("none");
      $(".card9 .text").addClass(" animate__animated animate__fadeInLeft");
      $(".card9 p").eq(0).removeClass("none");
      $(".card9 p").eq(0).addClass(" animate__animated animate__fadeInRight");
      $(".card9 p").eq(1).removeClass("none");
      $(".card9 p").eq(1).addClass(" animate__animated animate__fadeInRight");
      await sleep(500);
      $(".card9 p").eq(2).removeClass("none");
      $(".card9 p").eq(2).addClass(" animate__animated animate__fadeInRight");
      $(".card9 p").eq(3).removeClass("none");
      $(".card9 p").eq(3).addClass(" animate__animated animate__fadeInRight");
      await sleep(500);
      $(".card9 p").eq(4).removeClass("none");
      $(".card9 p").eq(4).addClass(" animate__animated animate__fadeInRight");
      $(".card9 .info").removeClass("none");
      $(".card9 .info").addClass(" animate__animated animate__fadeInRight");
      await sleep(500);
      $(".card9 p").eq(5).removeClass("none");
      $(".card9 p").eq(5).addClass(" animate__animated animate__fadeInRight");

      await sleep(2000);
      //card10
      this.currentIndex = 10;
      $(".card10 .title").removeClass("none");
      $(".card10 .title").addClass(" animate__animated animate__fadeInLeft");
      await sleep(1000);
      $(".card10 .more").removeClass("none");
      $(".card10 .more").addClass(" animate__animated animate__fadeInLeft");
      await sleep(1000);
      $(".card10 .img_box").removeClass("none");
      $(".card10 .img_box").addClass(" animate__animated animate__flash");
      await sleep(1000);
      $(".card10 #text").removeClass("none");
      this.playIn("fadeIn", "fadeOut", "#text");

      // 总时间 94 + 3秒
      if (this.ip) {
        setTimeout(() => {
          this.endRecord();
        }, 71000);
      }
    },
  },
};
</script>
<style scoped lang="less">
@import "./index.less";
</style>